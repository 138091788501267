import config from "../../../config/config.json";

let encodedCredentials = "";
try {
  // console.log(
  //   "==simplikaAuth=== url: ",
  //   config.SIMPLIKA_URL,
  //   "==SIMPLIKA_AUTH==",
  //   config.SIMPLIKA_AUTH
  // );
  if (
    config?.SIMPLIKA_URL &&
    config?.SIMPLIKA_AUTH?.isEnable &&
    config?.SIMPLIKA_AUTH?.userName &&
    config?.SIMPLIKA_AUTH?.password
  ) {
    let userName = config.SIMPLIKA_AUTH.userName;
    let password = config.SIMPLIKA_AUTH.password;
    encodedCredentials = Buffer.from(`${userName}:${password}`).toString(
      "base64"
    );
    //console.log("==simplikaAuth genrated:", encodedCredentials);
  }

  //console.log("==simplikaAuth encodedCredentials:", encodedCredentials);
} catch (error) {
  console.log("simplika auth error", error);
}

export default encodedCredentials;
