import config from "../../config/config.json";
import axios from "axios";
import simplikaAuth from "./auth/simplikaAuth";

export async function getApiData(url, req) {
  let resData = "";
  try {
    let apiDomain = getApiDomain();
    let apiUrl = apiDomain + url;
    let options = await getHeader(req);

    let data = await axios.get(apiUrl, options);

    resData = data;
  } catch (error) {
    console.log("error :*******", error);
    resData = error;
  }

  return resData;
}

export async function postApiData(url, requestData, req) {
  let resData = "";
  try {
    let apiDomain = getApiDomain();
    let apiUrl = apiDomain + url;
    let options = await getHeader(req);

    let data = await axios.post(apiUrl, requestData, options);
    resData = data;
  } catch (error) {
    console.log("error :", error);
    resData = error;
  }

  return resData;
}

export async function fetchPostData(url, requestOptions, req) {
  let resData = "";
  try {
    let apiDomain = getApiDomain();
    let apiUrl = apiDomain + url;
    let options = await getHeader(req);
    requestOptions.headers = options?.headers || requestOptions.headers;

    let response = await fetch(apiUrl, requestOptions);
    resData = response;
  } catch (error) {
    console.log("error :", error);
    resData = error;
  }
  return resData;
}

function getApiDomain() {
  let apiDomain = "";
  if (config?.SIMPLIKA_URL && config?.SIMPLIKA_AUTH?.isEnable) {
    apiDomain = config?.SIMPLIKA_URL;
  } else {
    apiDomain = config?.BASE_URL;
  }
  return apiDomain;
}

const getHeader = async (req) => {
  const systemIP =
    req?.headers["X-Client-IP"] ||
    req?.headers["x-client-ip"] ||
    req?.headers["x-forwarded-for"] ||
    req?.socket?.remoteAddress ||
    "";

  const userAgent =
    req?.headers["user-agent"] || req?.headers["x-client-user-agent"] || "";

  const deviceType = getDeviceType(userAgent);
  const osType = getOsType(userAgent);

  let deviceId = req?.headers["device_id"] || "";
  const clientData = req?.headers["client_data"];
  let ip = "";
  let clientIP = "";
  let location = "";

  if (clientData) {
    const getClientData = JSON.parse(clientData);

    ip = getClientData?.ip;
    deviceId = getClientData?.deviceId;
    clientIP = getClientData?.location?.ip;
    location = {
      city: getClientData?.location?.city,
      region: getClientData?.location?.region,
      country_code: getClientData?.location?.country_code,
      country_name: getClientData?.location?.country,
      continent_code: getClientData?.location?.continent_code,
      latitude: getClientData?.location?.latitude,
      longitude: getClientData?.location?.longitude,
    };
  }

  let options = {
    headers: {
      "Content-Type": "application/json",
      site_id: req?.headers?.site_id || "",
      device_type: deviceType || "desktop",
      req_type: "FE",
      access_token: req?.user?.accessToken || "",
      customer_id: req?.user?._id || "",
      customer_email: req?.user?.email || "",
      Authorization: simplikaAuth ? `Basic ${simplikaAuth}` : "",
      "x-client-location-ip": clientIP || "",
      "x-client-ip": ip || "",
      "x-client-user-agent": userAgent || "",
      "x-device-type": deviceType || "desktop",
      "x-device-id": deviceId || "",
      "x-req-type": "FE",
      "x-os-type": osType || "Windows",
      "x-location-city": location?.city
        ? encodeURIComponent(location?.city)
        : "",
      "x-location-country-code": location?.country_code
        ? encodeURIComponent(location?.country_code)
        : "",
      "x-location-country-name": location?.country_name
        ? encodeURIComponent(location?.country_name)
        : "",
      "x-location-region": location?.region
        ? encodeURIComponent(location?.region)
        : "",
      "x-location-latitude": location?.latitude || "",
      "x-location-longitude": location?.longitude || "",
    },
  };

  return options;
};

function getDeviceType(userAgent) {
  if (/Mobi|Android/i.test(userAgent)) {
    return "Mobile";
  } else if (/Tablet|iPad/i.test(userAgent)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
}

function getOsType(userAgent) {
  if (/windows/i.test(userAgent)) {
    return "Windows";
  } else if (/iphone/i.test(userAgent)) {
    return "iOS";
  } else if (/ipad/i.test(userAgent)) {
    return "iOS";
  } else if (/macintosh/i.test(userAgent)) {
    return "Mac OS";
  } else if (/Android/i.test(userAgent)) {
    return "Android";
  } else if (/Linux/i.test(userAgent)) {
    return "Linux";
  }
}

export function getApiDomainExport() {
  return getApiDomain();
}
export const getHeaderExport = async (req) => {
  let options = await getHeader(req);

  return options;
};
